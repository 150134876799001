@charset "utf-8";
@import "bulma/bulma.sass";

@include desktop {
  .scaling-button {
    @include button-large; }
  .scaling-control {
    @include control-large; }
  .scaling-icon {
    height: $icon-dimensions-large;
    width: $icon-dimensions-large; } }

@include tablet {
  .scaling-button {
    @include button-large; }
  .scaling-control {
    @include control-large; }
  .scaling-icon {
    height: $icon-dimensions-large;
    width: $icon-dimensions-large; } }


.scaling-button {
  margin: 5px; }
