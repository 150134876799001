$gardenSize: 15;
$grass: #C9E9A0;

.garden-container {
  position: relative;
  background-color: $grass;
  padding: 10px;
  border-radius: 25px;
}

.garden {
  display: grid;

  grid-template-columns: repeat($gardenSize, 1fr);
  margin: 5px;
}

.garden-row {

}

.garden-tile {
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: #006E2D;
  position: relative;
  box-sizing: border-box;
}

.hide-border-top {
  border-top: 1px solid transparent;
}

.hide-border-left {
  border-left: 1px solid transparent;
}

.garden-tile:before{
  content: ' ';
  display: block;
  width: 100%;
  padding-top: 100%;
}

.garden-tile-content {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.flower-1 {
  background-image: url("/assets/flower-1.png");
  background-size: contain;
}

.flower-2 {
  background-image: url("/assets/flower-2.png");
  background-size: contain;
}

.flower-3 {
  background-image: url("/assets/flower-3.png");
  background-size: contain;
}

.withering {
  opacity: 0.5;
}

.garden-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.garden-overlay-content {
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.dummy {
  flex: 1;
}