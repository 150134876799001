@charset "utf-8";
$fullhd-enabled: false;
@import "~bulma/bulma.sass";
@import "./style.sass";

.add-shadow {
  box-shadow: 0px 0px 20px 5px rgba(10, 10, 10, 0.3);
}

.flex-1 {
  flex: 1;
}

.score-entry {
  margin-bottom: 0.5rem !important;
}

.score-entry p{
  white-space: nowrap;
}

.score-place {
  font-size: 1.5em;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
  min-width: 34px;
}

.score-data {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin-bottom: 0;
}

.play-tag {
  position: absolute;
  top: 0;
  right: 0;
}
.play-tag button {
  padding: 5px;
}

.in-memoriam p{
  text-align: center;
  padding-bottom: 1em;
}

.in-memoriam a{
  text-align: center;
  color: $grey;
}
